<template>
  <div class="content p-0 bg-secondary">
    <div class="login-wrapper">
      <div class="row m-0">
        <div
          class="col-lg-5 side-banner"
          style="
            background-image: url('/static/images/login-banner.jpg');
            background-position: center center;
            background-size: cover;
            height: 100vh;
          "
        >
          <div class="content text-center d-flex justify-content-center h-100">
            <div class="align-self-center">
              <img
                src="/static/images/login-logo.png"
                class="mb-4 w-50"
                style="max-width: 200px"
                alt="logo"
              />
              <h4 class="text-white">
                Trabajamos por el progreso de las Franquicias en Iberoamérica
              </h4>
              <router-link to="/" class="btn btn-secondary">Inicio</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-7 site-content d-flex align-items-center">
          <div class="content" style="width: inherit">
            <div class="row">
              <div class="col-lg-8 mx-auto">
                <div class="card bg-secondary shadow border-0">
                  <div class="card-body px-lg-5 py-lg-5">
                    <div class="text-center text-muted mb-4">
                      <h6>
                        Introduce tus credenciales para entrar al panel de miembros FIAF
                      </h6>
                    </div>
                    <v-form action class="form" ref="form" v-model="valid">
                      <v-text-field
                        v-model="model.email"
                        :rules="[rules.required, rules.email]"
                        prepend-inner-icon="mdi-email-lock"
                        label="Correo electrónico"
                        hint=""
                        counter
                      ></v-text-field>
                      <v-text-field
                        v-model="model.password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show ? 'text' : 'password'"
                        prepend-inner-icon="mdi-lock"
                        label="Contraseña"
                        hint=""
                        counter
                        @click:append="show = !show"
                      ></v-text-field>
                      <span style="color: red" v-if="error !== ''">{{ error }}</span>
                    </v-form>
                    <div class="text-center text-muted mb-0" style="margin-top: 20px">
                      <a href="/forgot-password"
                        >¿Haz olvidado tu contraseña? <br /><small
                          >Haz click aquí para restaurarla</small
                        >
                      </a>
                    </div>
                    <div class="text-center">
                      <button
                        type="button"
                        class="btn btn-primary mt-4"
                        style="color: #fff"
                        :disabled="!valid"
                        @click.prevent="validate"
                      >
                        Iniciar Sesión
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        error: '',
        valid: true,
        loading: false,
        show: false,
        password: 'Password',
        rules: {
          required: (value) => !!value || 'Requerido',
          min: (v) => v.length >= 6 || 'Escriba por lo menos 6 caracteres',
          email: (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'El formato de correo electrónico inválido'
          },
        },
        model: {
          email: '',
          remenberMe: false,
          emailRules: [
            (v) => !!v || 'Correo electrónico es requerido',
            (v) => /.+@.+\..+/.test(v) || 'El formato de correo electrónico es invalido',
          ],
          password: '',
          passwordRules: [(v) => !!v || 'La contraseña es requerida'],
        },
        lazy: false,
      }
    },
    created() {
      this.$session.destroy()
    },
    methods: {
      validate() {
        if (this.$refs.form.validate()) {
          this.login()
        }
      },
      login() {
        axios
          .post('auth/login', {
            email: this.model.email,
            password: this.model.password,
          })
          .then((response) => {
            this.$session.start()
            this.$session.set('tokenSession', response.data.access_token)
            this.$session.set('userId', response.data.user.id)
            this.$session.set('userName', response.data.user.name)
            this.$session.set('userLastname', response.data.user.lastname)
            this.$session.set('userAvatar', response.data.user.avatar)
            this.$session.set('roles', response.data.user.roles)
            if (this.$route.query.redirect && this.$route.query.redirect != '') {
              this.$router.push(this.$route.query.redirect)
            } else {
              this.$router.push('/admin/dashboard')
            }
            this.loading = false
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.error = 'Correo electrónico o contraseña incorrecta'
                  break
                case 422:
                  this.error = 'Datos inválidos'
                  break
                default:
                  this.error = 'Ocurrió un error'
                  break
              }
            }
            this.loading = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .input-group-text {
    width: 40px;
  }
  .input-group .form-control:not(:first-child) {
    padding: 10px;
  }
</style>
